import axios from 'axios'
import config from 'config'
import { processResponseError } from './messages'
import { v4 as uuid } from 'uuid'
import { AUTHORIZATION_CODE } from '../../reducers/threeds'
import { captureException, captureMessage } from '@sentry/nextjs'
import { th } from 'date-fns/locale'

export const AUTHENTICATORS = {
  zap: 'whatsapp',
  sms: 'sms',
}
export const AUTHENTICATOR_DEFAULT = AUTHENTICATORS.zap
export const TOTAL_RETRIES = 20
export const WAIT_TIME = 1000

class PaymentLinkAPI {
  #successPayment = false
  #nsuPayment
  #advertisementId

  #source = `PaymentLink/${config.version}`

  static _instance = null

  #authToken = null

  #linkApi = axios.create({
    baseURL: config.paymentLinkApiEndpoint,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      ...(config.mode !== 'production' ? { Env: 'mock' } : {}),
    },
  })

  #apiV2 = axios.create({
    baseURL: config.infinitePayApiEndpoint,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  })

  #localApi = axios.create({
    baseURL: config.localApi,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  })

  constructor() {
    this.#nsuPayment = uuid()
    this.#advertisementId = uuid()
    return (PaymentLinkAPI._instance ||= this)
  }
  getNSU() {
    return this.#nsuPayment
  }
  setNSU() {
    this.#nsuPayment = uuid()
  }

  async login(cellphone, authentication = 'sms', moreParams = {}) {
    const data = {
      user: {
        phone_number: cellphone,
      },
      channel: authentication,
      ...moreParams,
    }

    if (authentication === AUTHENTICATORS.zap) {
      data.channel = AUTHENTICATORS.zap
    }

    return this.#apiV2.post('/shopper/mfa/sign_in', data)
  }

  async validateToken(token, interaction_id, cellphone, email, moreParams = {}) {
    const data = {
      user: {
        phone_number: cellphone,
        // email: email,
      },
      interaction_id: interaction_id,
      ...moreParams,
    }
    return this.#apiV2.post('/shopper/mfa/sign_in/confirm', data, {
      headers: this.mergeHeaders({ Authorization: token }),
    })
  }

  async finishToken(interaction_id) {
    return this.#apiV2.get('/shopper/mfa/sign_in/finish', {
      headers: this.mergeHeaders({ Authorization: interaction_id }),
    })
  }

  validJWT(token) {
    const parts = token.split('.')
    if (parts.length !== 3) {
      return false
    }
    return true
  }

  setAuthToken(token) {
    this.#authToken = token
  }

  async getToken() {
    const response = await this.finishToken(this.#authToken)
    if (this.validJWT(response.data.token)) {
      return response.data.token
    }
    throw new Error('invalid_token')
  }

  mergeHeaders(headers) {
    return {
      ...headers,
      'X-Source': this.#source,
      'X-Visitor-Hash': config?.fingerprint?.visitorId,
      'X-Request-Id': this.#advertisementId,
    }
  }

  async sendReceipt(payload, options = null) {
    try {
      const response = await this.#localApi.post(`/sendReceipt`, payload, options)
      return response.data
    } catch (err) {
      if (!err.response) {
        throw err
      }
      throw new Error(processResponseError(err.response || err.message))
    }
  }

  async fetchMerchantByHandle(handle, options = null) {
    try {
      const response = await this.#apiV2.get(`/merchants/${handle ?? ''}/configuration`, options)
      return response.data
    } catch (err) {
      if (!err.response) {
        throw err
      }
      throw new Error(processResponseError(err.response || err.message))
    }
  }

  async pay(handle, amount, payload = {}) {
    if (this.#successPayment) {
      throw new Error('already_paid')
    }
    try {
      const response = await this.#linkApi.post(
        `/${(handle && `${handle}/${amount ?? ''}`) || ''}`,
        payload,
        {
          headers: this.mergeHeaders({ Authorization: `Bearer ${await this.getToken()}` }),
        },
      )
      if (response.data?.data?.attributes?.authorization_code === AUTHORIZATION_CODE.ACCEPTED) {
        this.#successPayment = true
      }
      
      return response.data
    } catch (err) {
      if (err.response && this.canBeTriedAgain(err.response, payload)) {
        payload.retry = true
        return this.pay(handle, amount, payload)
      }
      throw new Error('unknown_error')
    }
  }

  async enrollment(handle, nsu, payload = {}) {
    try {
      const response = await this.#linkApi.post(`/${handle}/${nsu}/enrollment`, payload, {
        headers: this.mergeHeaders({ Authorization: `Bearer ${await this.getToken()}` }),
      })
      
      return response.data
    } catch (err) {
      if (err.response && this.canBeTriedAgain(err.response, payload)) {
        payload.retry = true
        return this.enrollment(handle, nsu, payload)
      }
      throw err
    }
  }

  async validate(handle, nsu, payload = {}) {
    try {
      const response = await this.#linkApi.post(`/${handle}/${nsu}/validate`, payload, {
        headers: this.mergeHeaders({ Authorization: `Bearer ${await this.getToken()}` }),
      })

      return response.data
    } catch (err) {
      if (err.response && this.canBeTriedAgain(err.response, payload)) {
        payload.retry = true
        return this.validate(handle, nsu, payload)
      }
      throw err
    }
  }


  #authJWTUnico = null
  async getTokenUnico() {
    if (!this.#authJWTUnico) {
      this.#authJWTUnico = await this.getToken()
    }
    return this.#authJWTUnico
  }

  async validateUnico(handle, nsu, count = 0, timer = 100) {
    try {
      await new Promise(
        (
          resolve, // pooling timeout
        ) =>
          setTimeout(() => {
            resolve()
          }, timer + timer * count),
      )
      

      const response = await this.#linkApi.post(
        `/${handle}/${nsu}/status`,
        {},
        {
          headers: this.mergeHeaders({ Authorization: `Bearer ${await this.getTokenUnico()}` }),
        },
      )

      return response.data
    } catch (err) {
      if (err.response && err.response.status === 401) {
        this.#authJWTUnico = null
        return await this.validateUnico(handle, nsu, count + 1, timer + WAIT_TIME)
      }
      if (err.response && this.shouldRetryUnicoValidate(err.response, count)) {
        return await this.validateUnico(handle, nsu, count + 1, timer + WAIT_TIME)
      }

      throw err
    }
  }

  async applePaySession(url) {
    try {
      const response = await this.#linkApi.post(`/apple-pay/session`, { url })
      return response.data
    } catch (err) {
      throw err
    }
  }

  async payWallet(handle, amount, payload = {}) {
    if (this.#successPayment) {
      throw new Error('already_paid')
    }
    try {
      const response = await this.#linkApi.post(
        `${(handle && `${handle}/${amount ?? ''}`) || ''}`,
        payload,
        { headers: { ...(config.mode !== 'production' ? { Env: 'mock' } : {}) } },
      )
      if (response.data?.data?.attributes?.authorization_code === AUTHORIZATION_CODE.ACCEPTED) {
        this.#successPayment = true
      }

      return response.data
    } catch (err) { 
      if (err.response && this.canBeTriedAgain(err.response, payload)) {
        payload.retry = true
        return this.payWallet(handle, amount, payload)
      }
      captureException(err)
      throw new Error('unknown_error')
    }
  }

  canBeTriedAgain(response, payload) {
    return response?.status !== 422 && !payload.retry
  }

  shouldRetryUnicoValidate(response, count) {
    return count < TOTAL_RETRIES && response?.status === 404
  }
}
export default new PaymentLinkAPI()

import { useCallback, useEffect, useState } from 'react'
import config from 'config'

import { RudderAnalytics } from '@rudderstack/analytics-js';

const writeKey = config.writeKey
const dataPlaneUrl = config.dataPlaneUrl


const useRudderStack = () => {
  const [ready, setReady] = useState(false);
  const [rudderStack, setAnalytics] = useState();

  useEffect(() => {
    if (!rudderStack) {
      const analyticsInstance = new RudderAnalytics();
      analyticsInstance.load(writeKey, dataPlaneUrl );

      analyticsInstance.ready(() => {
        setReady(true);
      });
      
      setAnalytics(analyticsInstance);
    }
  }, []);

  return { rudderStack, ready }
}

export default useRudderStack

import { ApplePayButton } from 'components/ui/form';
import { usePaymentContext } from 'context/PaymentContext';
import useApplePay from 'libs/useApplePay';
import { useWalletPayStore } from 'libs/useWalletPay';
import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { selectPayments } from 'reducers/payments';
import { useShallow } from 'zustand/react/shallow';
export function ApplePayConfiguredButton({}) {
  const { handleApplePay }  = useApplePay();
  const payments = useSelector(selectPayments)
  
  const amount = useWalletPayStore((state) => state.amount);
  const handleApplePayWithAmount = useCallback(() => {
    handleApplePay(amount)
  }, [handleApplePay, amount])

  return <ApplePayButton color='black' submitting={payments.fetching} onClick={handleApplePayWithAmount} >
    Pagar com
  </ApplePayButton>  

}
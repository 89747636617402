import linkApi from 'libs/infinitepay/link'
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { processResponseError } from 'libs/infinitepay/messages'
import { redirectToReceipt } from './redirectReceipt'
import { hideForm } from './payments'
import config from 'config'
import { captureException, captureMessage } from '@sentry/nextjs'
import { AUTHORIZATION_CODE } from './threeds'

const SHARED_PAYMENT_MESSAGE = 'shared'

export const sendValidade = createAsyncThunk(
  'unicopay/sendValidade',
  async (unicoParams, { rejectWithValue, getState, dispatch }) => {
    const { transaction, unicopay } = getState().unicopay
    try {
    
      if (!unicopay?.reference_id) {
        unicoParams?.rudderStack?.track('Payment Link | UnicoPay | Reference ID NOT FOUND')

        captureMessage(`unico reference_id not found ${JSON.stringify(unicopay)}`)
        captureException(new Error(`unico reference_id not found`))
        return rejectWithValue(
          'Oops! Parece que ocorreu um erro inesperado. Para uma melhor experiência, recomendamos que você tente acessar por um navegador.',
        )
      }

      unicoParams?.rudderStack?.track('Payment Link | UnicoPay | Polling Started', {
        amount: transaction?.receipt?.amount,
        brand: transaction?.receipt?.cardType,
        nsu: transaction?.nsu,
        merchant_id: transaction?.handle,
      })

      const response = await linkApi.validateUnico(transaction.handle, transaction.nsu)

      unicoParams?.rudderStack?.track('Payment Link | UnicoPay | Polling Finished', {
        amount: transaction?.receipt?.amount,
        brand: transaction?.receipt?.cardType,
        nsu: transaction?.nsu,
        merchant_id: transaction?.handle,
      })

      const {
        message,
        data,
      } = response
      
      let ret
      if (message == SHARED_PAYMENT_MESSAGE) {
        ret = { status: 'shared' }
      } else if (data?.attributes?.authorization_code == AUTHORIZATION_CODE.ACCEPTED) {
        ret = { status: 'accept' }
      } else {
        linkApi.setNSU()
        ret = { status: 'denied', code: data?.attributes?.authorization_code }
      }

      unicoParams?.rudderStack?.track('Payment Link | UnicoPay | Unico Finished', {
        amount: transaction?.receipt?.amount,
        brand: transaction?.receipt?.cardType,
        nsu: transaction?.nsu,
        merchant_id: transaction?.handle,
        ...ret,
      })

      if (ret.status == 'accept') {
        dispatch(hideForm())
        dispatch(redirectToReceipt(`${config.receiptsURL}/${transaction?.nsu}`))
      } else if (ret.status == 'shared') {
        dispatch(hideForm())
      }

      return ret
    } catch (err) {
      captureException(err)
      const error = processResponseError(err.response || err.message)

      unicoParams?.rudderStack?.track('Payment Link | UnicoPay | Unico Error', {
        amount: transaction?.receipt?.amount,
        brand: transaction?.receipt?.cardType,
        nsu: transaction?.nsu,
        merchant_id: transaction?.handle,
        error,
      })

      return rejectWithValue(error)
    }
  },
)

const unicoPaySlice = createSlice({
  name: 'unicopay',
  initialState: {
    loading: false,
    shared: false,
    error: null,
    transaction: null,
    nsu: null,
    start: false,
    unicopay: null,
  },
  reducers: {
    startUnicoPay: (state, action) => {
      state.loading = true
      state.transaction = action.payload.transaction
      state.unicopay = action.payload.unicopay
      state.nsu = action.payload.transaction.nsu
      state.start = true
    },
    resetUnicoPay: (state, action) => {
      state.loading = false
      state.error = null
      state.transaction = null
      state.nsu = null
      state.start = false
      state.unicopay = null
    },
  },
  extraReducers: {
    [sendValidade.pending]: (state, action) => {
      state.loading = true
      state.shared = false
      // reinitializing states to start second step
      state.challenge = state.form_post = state.error = null
    },
    [sendValidade.fulfilled]: (state, action) => {
      state.loading = false
      // cleaning states after second step
      state.challenge = state.form_post = state.error = null
      if (action.payload.status === 'denied') {
        // case catched erro, setting message
        state.error = action.payload.code
      } else if (action.payload.status === 'shared') {
        state.shared = true
      }
    },
    [sendValidade.rejected]: (state, action) => {
      state.loading = false
      state.shared = false
      // case uncatched erro, reinitializing states and set message
      state.error = action.payload
      state.challenge = state.form_post = null
    },
  },
})

const { actions, reducer } = unicoPaySlice

export const selectUnicoPay = (state) => state.unicopay

export const { startUnicoPay, resetUnicoPay } = actions

export default reducer
